import { useQuery } from "@tanstack/react-query";
import {
  Divider,
  Text,
} from "@totalenergiescode/mobility-business-rev-design-system";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";

import { getGraphqlClient } from "@/api/graphql/client";
import { GetAccountDocument } from "@/api/graphql/generated/graphql";
import { type BreadcrumbItem } from "@/components/shared/Breadcrumb/types.ts";
import { PageHeader } from "@/components/shared/PageHeader";
import { UsersList } from "@/components/Users/list/component";

import {
  StyledAddressContainer,
  StyledCard,
  StyledCardContainer,
  StyledCardContent,
  StyledCardTitle,
  StyledContainer,
  StyledIdentity,
  StyledRoundedTopRightCard,
  StyledSectionContainer,
  StyledTextContainer,
  StyledTitle,
} from "./styles";

export const AccountDetails: React.FC<{ id: string }> = ({ id }) => {
  const { t: tCompanies } = useTranslation("companies");
  const { t: tCommon } = useTranslation("common");
  const { user } = useAuth();
  const { data, isLoading: _isLoading } = useQuery({
    queryKey: ["account", id],
    enabled: !!user?.access_token,
    queryFn: async () => {
      if (!user?.access_token) return;

      return getGraphqlClient().request(GetAccountDocument, {
        request: {
          filters: {
            ids: [id],
          },
        },
      });
    },
  });
  const companyData = data?.AccountsMsSearchAccounts?.data?.[0];
  const breadcrumbItems: BreadcrumbItem[] = [
    {
      title: tCommon("common.features.dashboard"),
      path: "/" as const,
    },
    {
      title: companyData?.name || "",
      path: "/accounts/$id" as const,
    },
  ];

  return (
    <StyledContainer>
      <>
        {companyData?.name && (
          <PageHeader
            breadcrumbItems={breadcrumbItems}
            title={companyData?.name}
            href={""}
          />
        )}
        <StyledSectionContainer>
          <StyledTitle variant='title2'>
            {tCompanies("companies.list.subtitles.info")}
          </StyledTitle>
          <StyledCardContainer>
            <StyledCard>
              <StyledCardTitle variant='title2'>
                {tCompanies("companies.cards.companyName")}
              </StyledCardTitle>
              <Divider />
              <StyledCardContent variant='title2'>
                {companyData?.name}
              </StyledCardContent>
            </StyledCard>
            <StyledRoundedTopRightCard>
              <StyledCardTitle variant='title2'>
                {tCompanies("companies.cards.accountOwner")}
              </StyledCardTitle>
              <Divider />
              <StyledIdentity
                avatar={{
                  firstName:
                    companyData?.MainPrivilegeIdData?.UserIdData?.firstName,
                  lastName:
                    companyData?.MainPrivilegeIdData?.UserIdData?.lastName,
                  type: "initials",
                }}
                subtitle={companyData?.MainPrivilegeIdData?.UserIdData?.email}
                title={
                  companyData?.MainPrivilegeIdData?.UserIdData?.firstName +
                    " " +
                    companyData?.MainPrivilegeIdData?.UserIdData?.lastName ||
                  "N F"
                }
              />
            </StyledRoundedTopRightCard>
            <StyledCard>
              <StyledCardTitle variant='title2'>
                {tCompanies("companies.cards.accountNumber")}
              </StyledCardTitle>
              <Divider />
              <StyledCardContent variant='title2'>
                {companyData?.number}
              </StyledCardContent>
            </StyledCard>
            <StyledCard>
              <StyledCardTitle variant='title2'>
                {tCompanies("companies.cards.officeAddress")}
              </StyledCardTitle>
              <Divider />
              <StyledAddressContainer>
                <StyledTextContainer>
                  <Text
                    variant='text2'
                    dataTestId='order__order-ledgers-confirmation-company-name_text'
                  >
                    {companyData?.name}
                  </Text>
                  <Text variant='text2'>{`${companyData?.MainPrivilegeIdData?.UserIdData?.firstName} ${companyData?.MainPrivilegeIdData?.UserIdData?.lastName}`}</Text>
                </StyledTextContainer>
                <StyledTextContainer>
                  <Text variant='text2'>{companyData?.address}</Text>
                  <Text variant='text2'>
                    {`${companyData?.zipCode} ${companyData?.city} - ${companyData?.country}`}
                  </Text>
                </StyledTextContainer>
              </StyledAddressContainer>
            </StyledCard>
          </StyledCardContainer>
        </StyledSectionContainer>
        <UsersList accountId={id} />
      </>
    </StyledContainer>
  );
};
