import { BubbleTextProps } from "@totalenergiescode/mobility-business-rev-design-system";
import { match, P } from "ts-pattern";

import { NotificationStatusType } from "@/api/graphql/notifications/types.ts";

export const getColorFromNotificationStatus = (
  status: NotificationStatusType,
): BubbleTextProps["variant"] =>
  match(status)
    .with("NOTIFICATION_STATUS_UNSPECIFIED", () => "orange" as const)
    .with("HANDLED", () => "green" as const)
    .with(
      P.union(
        "ABORTED_ANTI_FLOOD",
        "ABORTED_NO_REGISTERED_USER",
        "ABORTED_NO_SENDING_SOLUTION",
        "ABORTED_NO_TEMPLATE",
        "ABORTED_NO_TRIGGER",
      ),
      () => "grey" as const,
    )
    .otherwise(() => "grey" as const);