import { Link } from "@tanstack/react-router";
import {
  Add,
  Button,
  Search,
} from "@totalenergiescode/mobility-business-rev-design-system";
import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";

import { useGetCountAgentsByStatus } from "@/api/graphql/agents/search";
import { AgentsListComponent, AgentsListProps } from "@/components/Agents/List";
import { RoundedTopLeftContainer } from "@/components/containers/RoundedTopLeftContainer";
import { Loading } from "@/components/shared/Loading";
import { TabMenuButton } from "@/components/shared/TabMenuButton";

import {
  StyledDivider,
  StyledPageHeader,
  StyledSearchField,
  StyledSearchFieldContainer,
  StyledTabMenuContainer,
  StyledTitle,
} from "./styles";

const BackOffice = () => {
  const {
    data: agentsCountByStatus,
    isLoading,
    refetch,
  } = useGetCountAgentsByStatus();
  const countStatus = {
    all: agentsCountByStatus?.all || "0",
    enabled: agentsCountByStatus?.enabled || "0",
    disabled: agentsCountByStatus?.disabled || "0",
    inviting: agentsCountByStatus?.inviting || "0",
  };
  const { t: tUsers } = useTranslation("users");
  const { t: tAgents } = useTranslation("agents");
  const { t: tCommon } = useTranslation("common");
  const { t: tCompanies } = useTranslation("companies");
  const [selectedTab, setTabSelected] = useState(0);
  const [queryString, setQueryString] = useState("");
  const [agentListFilters, setAgentListFilters] = useState<
    Partial<AgentsListProps>
  >({});
  const breadcrumbItems = [
    {
      title: tCommon("common.features.dashboard"),
      path: "/" as const,
    },
    {
      title: tUsers("users.forms.backOfficeAgents"),
      path: "/backoffice-users" as const,
    },
  ];
  const tabMenuContent = [
    {
      label: tAgents("agents.filters.all"),
      dataTestId: "tab-menu-button",
      hasBubbleText: true,
      isBadge: false,
      text: countStatus.all,
      variant: "grey",
      onClick: () => {
        setAgentListFilters((state) => ({
          ...state,
          showEnabledUsers: undefined,
          showInvitedUsers: undefined,
          showDisabledUsers: undefined,
          showUnknownUsers: undefined,
        }));
      },
    },
    {
      label: tAgents("agents.filters.activated"),
      dataTestId: "tab-menu-button",
      hasBubbleText: true,
      isBadge: false,
      text: countStatus.enabled,
      variant: "green",
      onClick: () => {
        setAgentListFilters((state) => ({
          ...state,
          showEnabledUsers: true,
          showInvitedUsers: false,
          showDisabledUsers: false,
          showUnknownUsers: false,
        }));
      },
    },
    {
      label: tAgents("agents.filters.waiting"),
      dataTestId: "tab-menu-button",
      hasBubbleText: true,
      isBadge: false,
      text: countStatus.inviting,
      variant: "orange",
      onClick: () => {
        setAgentListFilters((state) => ({
          ...state,
          showEnabledUsers: false,
          showInvitedUsers: true,
          showDisabledUsers: false,
          showUnknownUsers: false,
        }));
      },
    },
    {
      label: tAgents("agents.filters.disabled"),
      dataTestId: "tab-menu-button",
      hasBubbleText: true,
      isBadge: false,
      text: countStatus.disabled,
      variant: "grey",
      onClick: () => {
        setAgentListFilters((state) => ({
          ...state,
          showEnabledUsers: false,
          showInvitedUsers: false,
          showDisabledUsers: true,
          showUnknownUsers: false,
        }));
      },
    },
  ];
  const handleSearchChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setQueryString(() => evt.target.value);
    setAgentListFilters((state) => ({
      ...state,
      queryString: evt.target.value,
    }));
  };

  return (
    <Loading isLoading={isLoading}>
      <>
        <StyledPageHeader
          breadcrumbItems={breadcrumbItems}
          title={tUsers("users.forms.backOfficeAgents")}
          href={""}
          rightContent={
            <Link to='/backoffice-users/add'>
              <Button
                leftIcon={<Add width={25} height={25} color='white' />}
                variant='primary'
                isLowercase
                buttonText={tUsers("users.forms.addAgent")}
                onClick={() => {}}
              />
            </Link>
          }
        />
        <RoundedTopLeftContainer>
          <StyledTitle variant='title3' fontWeight='medium'>
            {tAgents("agents.list.title")}
          </StyledTitle>
          <StyledTabMenuContainer>
            {tabMenuContent.map((tab: any, index: number) => (
              <TabMenuButton
                content={tab}
                key={`tab_${index}`}
                index={index}
                isSelectedTab={index === selectedTab}
                setTabSelected={() => setTabSelected(index)}
                dataTestId='tab-menu-button'
              />
            ))}
          </StyledTabMenuContainer>

          <StyledDivider />
          <StyledSearchFieldContainer>
            <StyledSearchField
              dataTestId='text-field'
              onChange={handleSearchChange}
              placeholder={tCompanies("companies.list.searchPlaceholder")}
              startIcon={<Search width={18} height={18} />}
              value={queryString}
            />
          </StyledSearchFieldContainer>

          <AgentsListComponent
            {...agentListFilters}
            refetchCountStatus={refetch}
          />
        </RoundedTopLeftContainer>
      </>
    </Loading>
  );
};

export default BackOffice;
