import { capitalize } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import {
  BubbleText,
} from "@totalenergiescode/mobility-business-rev-design-system";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { GetUserQuery } from "@/api/graphql/accounts/getUser/queries.ts";
import { getGraphqlClient } from "@/api/graphql/client.ts";
import { useGetNotificationsByUser } from "@/api/graphql/notifications/hooks.ts";
import { NotificationStatusType } from "@/api/graphql/notifications/types.ts";
import { StyledCellText } from "@/components/Agents/List";
import { useFormattedDate } from "@/components/Agents/List/useFormattedDate.ts";
import { getColorFromNotificationStatus } from "@/components/Notifications/list/helper.ts";

export const useNotificationTableData = (userId: string) => {
  const { t: tCompanies } = useTranslation("companies");
  const { t: tNotifications } = useTranslation("notifications");
  const formattedDate = useFormattedDate();
  const { data: notifications, isLoading: isNotificationsLoading } =
    useGetNotificationsByUser(userId);
  const listOfNotifications = notifications?.data;
  const total = notifications?.total;
  const columns = useMemo(
    () => [
      {
        columnName: tCompanies("companies.list.title"),
        displayedByDefault: true,
      },
      {
        columnName: tNotifications("notifications.table.object"),
        displayedByDefault: true,
      },
      {
        columnName: tNotifications("notifications.table.attachment"),
        displayedByDefault: true,
      },
      {
        columnName: tNotifications("notifications.table.status"),
        displayedByDefault: true,
      },
      {
        columnName: tNotifications("notifications.table.dateSent"),
        displayedByDefault: true,
      },
    ],
    [tCompanies, tNotifications],
  );
  const rows = useMemo(() => {
    if (!listOfNotifications) return [];

    const getNotificationStatusTranslation = (status: NotificationStatusType) =>
      tNotifications(`notifications.status.${status}`, {
        defaultValue: status,
      });

    return listOfNotifications.map((notification) => {
      const translatedStatus = `${getNotificationStatusTranslation(notification?.status)} ${
        notification?.failedReason || ""
      }`;
      const color = getColorFromNotificationStatus(notification?.status);

      return {
        data: {
          account: `${notification?.accountNumber} - ${notification.accountName}`,
          subject: notification?.subject,
          attachments:
            notification?.hasAttachments === false
              ? tNotifications("notifications.attachments.without")
              : tNotifications("notifications.attachments.with"),
          status: (
            <StyledCellText variant='text2'>
              <BubbleText text={capitalize(translatedStatus)} variant={color} />
            </StyledCellText>
          ),
          dateSent: formattedDate(notification?.createdTs),
        },
        key: notification.id,
      };
    });
  }, [listOfNotifications, tNotifications, formattedDate]);
  const totalNumberOfPages = total ?? 0;
  const { data: userData, isLoading: isUserLoading } = useQuery({
    queryKey: ["user", userId],
    queryFn: async () =>
      getGraphqlClient().request(GetUserQuery, {
        request: { filters: { ids: [userId] } },
      }),
  });
  const user = userData?.AccountsMsSearchUsers?.data
    ? {
        firstName: userData.AccountsMsSearchUsers?.data?.[0].firstName,
        lastName: userData.AccountsMsSearchUsers?.data?.[0].lastName,
        email: userData.AccountsMsSearchUsers?.data?.[0].email,
      }
    : null;

  return {
    columns,
    rows,
    total,
    totalNumberOfPages,
    isUserLoading,
    isNotificationsLoading,
    user,
  };
};

export const useBreadCrumbItems = (fullname: string) => {
  const { t: tCommon } = useTranslation("common");
  const { t: tNotifications } = useTranslation("notifications");

  return [
    {
      title: tCommon("common.features.dashboard"),
      path: "/" as const,
    },
    {
      title: fullname || "",
      path: "/users/$id" as const,
    },
    {
      title: tNotifications("notifications.title"),
      path: undefined,
    },
  ];
};
