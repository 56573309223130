import { useFormatDate } from "@/hooks/i18n/useFormatDate";

export const useFormattedDate = () => {
  const formatDate = useFormatDate();
  const DEFAULT_VALUE = "";

  return (date: string | number | null | undefined) => {
    if (date == null || date === "") return DEFAULT_VALUE;

    try {
      const timestamp = typeof date === "string" ? parseInt(date, 10) : date;

      if (isNaN(timestamp)) return DEFAULT_VALUE;

      const millisecondsToDateObject = new Date(Number(timestamp) * 1000);

      if (millisecondsToDateObject.toString() === "Invalid Date")
        return DEFAULT_VALUE;

      return formatDate(millisecondsToDateObject, "short");
    } catch (error) {
      console.error("Error formatting date:", error);

      return DEFAULT_VALUE;
    }
  };
};
