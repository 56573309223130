import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { dateFormatOptions } from "@/hooks/i18n/useFormatDate/constants";
import { isAppLocale } from "@/types/i18n";
import { isValidDate } from "@/utils/date/utils";

export const useFormatDate = () => {
  const { i18n } = useTranslation();
  const { t: tCommon } = useTranslation("common");
  const formatDate = useCallback(
    (date: Date, format: keyof typeof dateFormatOptions = "standard") => {
      if (!isValidDate(date) || !i18n.language || !isAppLocale(i18n.language)) {
        return "-";
      }
      let formatedDate = date.toLocaleDateString(
        i18n.language,
        dateFormatOptions[format],
      );

      if (format === "full") {
        formatedDate = formatedDate.replace(":", "h");
      }
      if (format === "dayAndMonth" && i18n.language.includes("fr")) {
        const [day, month] = formatedDate.split(" ");

        if (Number(day) === 1) {
          return `${day}${tCommon("common.orderSuffix.first")} ${month}`;
        }
      }

      return formatedDate;
    },
    [i18n, tCommon],
  );

  return formatDate;
};
