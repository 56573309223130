import { coerce, string, z } from "zod";

export const envSchema = z.object({
  OIDC_AUTHORITY: string(),
  OIDC_CLIENT_ID: string(),
  OIDC_ISSUER: string(),
  OIDC_REDIRECT_URI: string(),
  OIDC_SIGN_OUT_PATH: string(),
  BASE_PATH: string(),
  PORT: coerce.number(),
  HOST: string(),
  API_URL: string(),
});

export type Env = z.infer<typeof envSchema>;

export const env = envSchema.parse({
  OIDC_AUTHORITY: import.meta.env.VITE_OIDC_AUTHORITY,
  OIDC_CLIENT_ID: import.meta.env.VITE_OIDC_CLIENT_ID,
  OIDC_ISSUER: import.meta.env.VITE_OIDC_ISSUER,
  OIDC_REDIRECT_URI: import.meta.env.VITE_OIDC_REDIRECT_URI,
  OIDC_SIGN_OUT_PATH: import.meta.env.VITE_OIDC_SIGN_OUT_PATH,
  PORT: import.meta.env.VITE_PORT,
  HOST: import.meta.env.VITE_HOST,
  BASE_PATH: `https://${import.meta.env.VITE_HOST}:${import.meta.env.VITE_PORT}/`,
  API_URL: import.meta.env.VITE_API_URL,
});
