import { getGraphqlClient } from "@/api/graphql/client.ts";
import { NOTIFICATIONS_PER_PAGE } from "@/api/graphql/notifications/constants.ts";
import { mapNotificationsFromGraphQL } from "@/api/graphql/notifications/mappers.ts";
import { GetNotificationsByUserQuery } from "@/api/graphql/notifications/queries.ts";
import { GetNotificationsRequest } from "@/api/graphql/notifications/types.ts";
import {
  GetNotificationsByUserQueryQuery,
  NotificationsMsSearchNotificationsResponseData,
} from "@/api/graphql/types/graphql.ts";

export const GetNotificationsByUser = async ({
                                               filters,
                                               paging,
                                               sorts,
                                             }: GetNotificationsRequest) => {
  const page = paging?.page || 1;
  const size = paging?.size || NOTIFICATIONS_PER_PAGE;
  const userId = filters?.userId || "";
  const response =
    await getGraphqlClient().request<GetNotificationsByUserQueryQuery>(
      GetNotificationsByUserQuery,
      {
        request: {
          filters: {
            userId,
          },
          paging: {
            page,
            size,
          },
          sorts,
        },
      },
    );
  const rawNotifications = response?.NotificationsMsSearchNotifications
    ?.data as NotificationsMsSearchNotificationsResponseData[] | null;

  if (!rawNotifications) {
    return { data: [], total: 0 };
  }

  const notifications = mapNotificationsFromGraphQL(rawNotifications);
  const total = response?.NotificationsMsSearchNotifications?.pagination?.total || 0;

  return { data: notifications, total };
};