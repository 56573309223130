import { useQuery } from "@tanstack/react-query";

import { queries } from "@/api/graphql/constants.ts";
import { GetNotificationsByUser } from "@/api/graphql/notifications/api.ts";
import {
  NotificationsMsSearchNotificationsRequestSortField,
} from "@/api/graphql/types/graphql.ts";

export const useGetNotificationsByUser = (userId: string) =>
  useQuery({
    queryKey: [queries.GET_NOTIFICATIONS_BY_USER, userId],
    queryFn: () =>
      GetNotificationsByUser({
        filters: { userId: userId },
        sorts: {
          field:
            NotificationsMsSearchNotificationsRequestSortField.CreatedTs,
          ascending: false,
        },
      }),
  });
