import { useTranslation } from "react-i18next";

export const Utils = () => {
  const { t: tUsers } = useTranslation("users");

  return [
    {
      label: tUsers("users.forms.agent"),
      value: "AGENT",
    },
    {
      label: tUsers("users.forms.superAgent"),
      value: "SUPER_AGENT",
    },
  ];
};
