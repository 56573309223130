import { Divider } from "@totalenergiescode/mobility-business-rev-design-system";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { NOTIFICATIONS_PER_PAGE } from "@/api/graphql/notifications/constants.ts";
import {
  useBreadCrumbItems,
  useNotificationTableData,
} from "@/components/Notifications/list/hooks";
import {
  StyledCard,
  StyledCardContainer,
  StyledCardTitle,
  StyledCurrentCountOnTotalContainer,
  StyledCurrentCountOnTotalText,
  StyledCustomTable,
  StyledCustomTableContainer,
  StyledEmailText,
  StyledPageHeader,
} from "@/components/Notifications/list/styles.ts";
import { NotificationsListProps } from "@/components/Notifications/list/types.ts";
import { EmptyDataForThisFilter } from "@/components/shared/EmptyComponent";
import { Loading } from "@/components/shared/Loading";

export const NotificationList: React.FC<NotificationsListProps> = ({
  id: userId,
}) => {
  const { t: tCommon } = useTranslation("common");
  const { t: tNotifications } = useTranslation("notifications");
  const [currentPage, setCurrentPage] = useState(1);
  const {
    columns,
    rows,
    total,
    totalNumberOfPages,
    isUserLoading,
    isNotificationsLoading,
    user,
  } = useNotificationTableData(userId);
  const breadcrumbItems = useBreadCrumbItems(
    `${user?.firstName} ${user?.lastName}`,
  );

  return (
    <>
      <StyledPageHeader
        breadcrumbItems={breadcrumbItems}
        title={tNotifications("notifications.title")}
        href={""}
      />
      <Loading isLoading={isUserLoading || false}>
        <StyledCardContainer>
          <StyledCard>
            <StyledCardTitle variant='title2'>
              {tNotifications("notifications.list.sentTo")}
            </StyledCardTitle>
            <Divider />
            <StyledEmailText variant={"text2"}>
              {user?.email || ""}
            </StyledEmailText>
          </StyledCard>
        </StyledCardContainer>
      </Loading>
      <Loading isLoading={isNotificationsLoading || false}>
        <StyledCustomTableContainer>
          <StyledCustomTable
            rows={rows}
            columns={columns}
            loadMoreButton={{
              dataTestId: "notifications__list_load-more-button",
              label: tCommon("common.pagination.nextPage", {
                pageSize: NOTIFICATIONS_PER_PAGE,
              }),
              onClick: () => setCurrentPage((prev) => prev + 1),
              isDisabled: currentPage >= totalNumberOfPages,
            }}
          />
          {total === 0 && (
            <EmptyDataForThisFilter
              principalText={tNotifications("notifications.table.noData")}
            />
          )}
          <StyledCurrentCountOnTotalContainer>
            <StyledCurrentCountOnTotalText variant='sub3'>
              {tNotifications(
                "notifications.namesWithCurrentCountOnTotal.name",
                {
                  count: rows.length,
                  total,
                },
              )}
            </StyledCurrentCountOnTotalText>
          </StyledCurrentCountOnTotalContainer>
        </StyledCustomTableContainer>
      </Loading>
    </>
  );
};
