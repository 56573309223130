import { graphql } from "@/api/graphql/types/gql";

export const GetUserQuery = graphql(`
  query GetUser($request: AccountsMsSearchUsersRequest) {
    AccountsMsSearchUsers(request: $request) {
      data {
        id
        email
        locale
        timezone
        firstName
        lastName
        phone
        phoneAlt
        jobTitle
      }
    }
  }
`);
